import { Component } from 'react';

import PropTypes from 'prop-types';
import CSSTransition from 'react-transition-group/CSSTransition';

class Notification extends Component {
  componentDidMount() {
    const { notification, onRemove } = this.props;
    const { autoDismiss } = notification;
    onRemove(notification.id, autoDismiss * 1000);
  }

  render() {
    const {
      notification: { id, type, message, link, showCheck },
      ...rest
    } = this.props;
    const modifiedRest = Object.assign({}, rest);
    delete modifiedRest.onRemove;

    return (
      <CSSTransition classNames="humane-zola" {...modifiedRest} timeout={600}>
        <div id={`notification-${id}`} className={`humane-zola humane-zola-${type}`}>
          <div>
            {showCheck ? <span className="zolaicon zolaicon-checkmark" /> : null}
            {message}
            {link ? <a href={link.path}>{link.label}</a> : null}
          </div>
        </div>
      </CSSTransition>
    );
  }
}

Notification.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    message: PropTypes.string,
    showCheck: PropTypes.bool,
    link: PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.string,
    }),
  }),
  onRemove: PropTypes.func,
};

export default Notification;
