import { Toasts } from '@zola/zola-ui/src/components/Toasts';
import * as toastsActions from '@zola-helpers/client/dist/es/redux/toasts/toastsActions';

import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  toasts: state.toasts,
});

const mapDispatchToProps = (dispatch) => ({
  onRemoveToast: (id) => dispatch(toastsActions.hide(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toasts);
