import getEnvironment, { EnvironmentTypes } from '@zola-helpers/client/dist/es/util/environment';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { applyMiddleware, compose, createStore, combineReducers, AnyAction } from 'redux';
import { createTracker } from 'redux-segment';
import thunk, { ThunkDispatch } from 'redux-thunk';

import reducers from '../reducers';

// Create Segment Tracker
const tracker = createTracker();

/* eslint no-underscore-dangle: ["error", { "allow": ["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] }] */
const composeEnhancers =
  getEnvironment() !== EnvironmentTypes.PRODUCTION && typeof window !== 'undefined'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const enhancers = composeEnhancers(applyMiddleware(thunk, tracker));

export const finalReducers = combineReducers({
  ...reducers,
});

const store = createStore(finalReducers, enhancers);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<RootState, unknown, AnyAction>;

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
